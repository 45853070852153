import React from 'react';
import Layout from '../components/layout';
import FacilitatorsComponent from '../components/facilitators/';

const headerText = 'FACILITATORS';

const Facilitators = () => {
  return (
    <Layout headerText={headerText} heroBg="grass">
      <FacilitatorsComponent />
    </Layout>
  );
};

export default Facilitators;
