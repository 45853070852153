import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import './styles.scss';
import '../../pages/page-styles.scss';

const FacilitatorsComponent = () => {
  const data = useStaticQuery(graphql`
    {
      intro: contentfulFacilitators(title: { eq: "Introduction" }) {
        title
        content {
          raw
        }
      }
      schools: contentfulFacilitators(
        title: { eq: "Schools Enrolled in iDECIDE" }
      ) {
        title
        content {
          raw
        }
        mapLink
      }
      become: contentfulFacilitators(
        title: { eq: "How to Become an iDECIDE Facilitator" }
      ) {
        title
        content {
          raw
        }
      }
      addtl: contentfulFacilitators(
        title: { eq: "Additional Training Opportunities" }
      ) {
        title
        content {
          raw
        }
      }
    }
  `);

  const { intro, schools, become, addtl } = data;
  const paragraph = JSON.parse(intro.content.raw);
  const title = intro.title;
  const schoolTitle = schools.title;
  const schoolContent = JSON.parse(schools.content.raw);
  const becomeTitle = become.title;
  const becomeContent = JSON.parse(become.content.raw);
  const addtlTitle = addtl.title;
  const addtlContent = JSON.parse(addtl.content.raw);

  const losLink =
    'https://assets.ctfassets.net/baf8gsqzyl4n/6AnFUjJKNY0sUXnXKjlHhD/9a14debb055dbcea366056652838f3cf/iDECIDE_LOS.docx';

  return (
    <div className="content">
      <div id="Introduction" className="anchor">
        <section className="section has-background-info">
          <div className="container">
            <div>
              <h3 className="is-uppercase is-size-3 has-text-left">{title}</h3>
              {renderRichText(paragraph)}
              {/* removed from contentful bc hyperlink to asset is funky when parsing */}
              If your school and/or district is not currently enrolled in
              iDECIDE and you are interested in joining, please{' '}
              <a href={losLink}>see here for our template LOS</a>. All schools
              trained in the iDECIDE must provide a LOS on school or district
              letterhead. We ask that the appropriate representative sign and
              return on school/district letterhead. You are welcome to provide
              one letter of support to cover multiple schools within the same
              district. Please return any LOS to Caroline Gray (project manager,{' '}
              <a href="mailto:cgray16@mgh.harvard.edu">
                cgray16@mgh.harvard.edu
              </a>
              ) or faxed to 617-643-1998.
            </div>
          </div>
        </section>
      </div>
      <div id="Schools Enrolled in iDECIDE" className="anchor">
        <section className="section">
          <div className="container">
            <div>
              <h3 className="is-size-3">{schoolTitle}</h3>
              <div className="columns">
                <div className="column is-8">
                  {renderRichText(schoolContent)}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section has-background-info">
        <div className="has-text-centered">
          <iframe
            title="Schools offering iDECIDE"
            src={schools.mapLink}
            width="90%"
            height="480"
          ></iframe>
        </div>
      </section>
      <section
        id="Become an iDECIDE Facilitator"
        className="section has-background-info pt-0"
        style={{ scrollMarginTop: '8rem' }}
      >
        <div className="container">
          <div>
            <h3 className="is-size-3">{becomeTitle}</h3>
            {renderRichText(becomeContent)}
          </div>
        </div>
      </section>
      <section
        id="Additional Training Opportunities"
        className="section has-background-info pt-0"
        style={{ scrollMarginTop: '8rem' }}
      >
        <div className="container">
          <div>
            <h3 className="is-size-3">{addtlTitle}</h3>
            {renderRichText(addtlContent)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FacilitatorsComponent;
